import React from 'react';

export default function SocialNetworkBg({ className }: any) {
  return (
    <svg className={className} width="343" height="79" viewBox="0 0 343 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H343V79H77.664C67.8486 79 58.5761 74.4953 52.5091 66.7795L0 0Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="279.531" y1="-50.2727" x2="130.129" y2="126.098" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="1" stopColor="#9E247A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

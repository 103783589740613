import React from 'react'

export default function SharedIcon({ className }: any) {
  return (
    <svg className={className} width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1714 8.5286C15.8644 8.9973 16.6854 9.27301 17.5682 9.27301C20.0136 9.27301 22 7.196 22 4.6365C22 2.0816 20.0092 0 17.5682 0C15.1228 0 13.132 2.07701 13.132 4.6365C13.132 5.06385 13.1938 5.482 13.2997 5.87719L10.6733 7.83013L6.51084 10.9181C5.88845 10.5734 5.18215 10.3758 4.43175 10.3758C1.98632 10.3758 0 12.4528 0 15.0123C0 17.5673 1.99074 19.6443 4.43175 19.6443C5.48672 19.6443 6.45344 19.2537 7.21709 18.6104L10.6733 21.1744L13.2997 23.1228C13.1938 23.518 13.132 23.9315 13.132 24.3635C13.132 26.9184 15.1228 29 17.5682 29C20.0136 29 22 26.923 22 24.3635C22 21.8086 20.0092 19.7316 17.5682 19.7316C16.6854 19.7316 15.86 20.0073 15.1669 20.476L11.4061 17.6821L8.80616 15.7522C8.84148 15.5086 8.86797 15.2651 8.86797 15.0123C8.86797 14.4517 8.76644 13.9141 8.58988 13.4178L11.4061 11.327L15.1714 8.5286ZM8.63401 16.4828L11.0441 18.2703L14.6108 20.9217C14.1649 21.3445 13.7985 21.8545 13.5425 22.4335L11.0397 20.5771L7.71589 18.1095C8.12199 17.6454 8.43537 17.094 8.63401 16.4828ZM8.30737 12.7561C8.01162 12.2001 7.60549 11.713 7.11994 11.327L11.0397 8.4183L13.5425 6.56187C13.7985 7.14086 14.1649 7.65551 14.6108 8.07827L11.0397 10.7297L8.30737 12.7561Z" fill="white" />
      <defs>
        <linearGradient id="svg-linear-bg-1" x1="20.625" y1="5.44344e-08" x2="6.26481" y2="25.4731" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="1" stopColor="#9E247A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";

import ContactIcon from "../svg/contact";
import GrillIcon from "../svg/grill";
import HomeIcon from "../svg/home";
import { contact, grill } from '../../assets/data/links.json';

import "./style.css";

interface HeaderProps {
  siteTitle?: string;
  size?: "small" | "auto",
  className?: string;
}
/**
 * @name Header
 * @description navbar menu at header of the page
 * @author siemah
 */
const Header = ({ siteTitle, className }: HeaderProps) => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <>
    <nav className={`navbar navbar-expand-sm position-fixed z-index_2 w-100 py-0 ${className || ''}`}>

      <Link title={siteTitle} className="navbar-brand p-0 m-0" to='/'>
        <img className='navbar-brand__img' height={60} width={245} alt='algone' src={data?.placeholderImage?.publicURL} />
      </Link>

      <div className={`collapse navbar-collapse mb-0 show flex_1`} id="navbarHeaderMenuContent">
        <ul className="navbar-nav d-flex flex-row justify-content-end align-items-center flex_1">
          <li className="nav-item navbar__item dropdown text-uppercase text-center">
            <Link
              activeClassName="active"
              className={`nav-link with-hover ${className}`}
              to={'/'}>
              <HomeIcon className='nav-icon' />
              <span className="link-text text-white d-block text-center">Accueil</span>
            </Link>
          </li>
          <li className="nav-item navbar__item dropdown text-uppercase text-center">
            <Link
              activeClassName="active"
              className={`nav-link with-hover ${className}`}
              to={grill}>
              <GrillIcon className='nav-icon' />
              <span className="link-text text-white d-block text-center">Grill</span>
            </Link>
          </li>
          <li className="nav-item navbar__item dropdown text-uppercase text-center">
            <Link
              activeClassName="active"
              className={`nav-link with-hover ${className}`}
              to={contact}>
              <ContactIcon className='nav-icon' />
              <span className="link-text text-white d-block text-center">Contact</span>
            </Link>
          </li>
        </ul>
      </div>

    </nav>
    <div className="nav-fake" id='top__block'></div>
    </>
  );
}

export default Header;

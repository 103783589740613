import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function HomeIcon({ className }: KeyValueObject<string>) {
  return (
    <svg className={className} width="46" height="35" viewBox="0 0 46 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.8866 15.0984L40.9625 13.4078L21.3141 2.06348L18.39 0.376531C16.5362 -0.693686 14.218 0.644998 14.218 2.78543V7.60322H21.3141C21.9961 7.60322 22.5512 8.15827 22.5512 8.84031C22.5512 9.52235 21.9961 10.0774 21.3141 10.0774H7.50645C6.82079 10.0774 6.26935 10.6325 6.26935 11.3145C6.26935 11.9965 6.82442 12.5516 7.50645 12.5516H18.8834C19.5654 12.5516 20.1205 13.1067 20.1205 13.7887C20.1205 14.4707 19.5654 15.0258 18.8834 15.0258H2.2642C1.57853 15.0258 1.0271 15.5809 1.0271 16.2629C1.0271 16.9449 1.58216 17.5 2.2642 17.5H29.9991C30.6848 17.5 31.2362 18.0551 31.2362 18.7371C31.2362 19.4191 30.6812 19.9742 29.9991 19.9742H8.10141C7.41575 19.9742 6.86432 20.5293 6.86432 21.2113C6.86432 21.8933 7.41938 22.4484 8.10141 22.4484H23.0083C23.6903 22.4484 24.2454 23.0035 24.2454 23.6855C24.2454 24.3675 23.6903 24.9226 23.0083 24.9226H5.32611C4.64044 24.9226 4.08901 25.4776 4.08901 26.1597C4.08901 26.8417 4.64407 27.3968 5.32611 27.3968H14.2216V32.2146C14.2216 34.355 16.5398 35.6937 18.3936 34.6235L21.3177 32.9365L40.9661 21.5922L43.8902 19.9053C45.7404 18.8459 45.7404 16.1686 43.8866 15.0984ZM26.4729 15.0331H23.6214C22.9393 15.0331 22.3843 14.478 22.3843 13.796C22.3843 13.1103 22.9393 12.5589 23.6214 12.5589H26.4729C27.1585 12.5589 27.71 13.1139 27.71 13.796C27.71 14.478 27.1549 15.0331 26.4729 15.0331Z" fill="svg-linear-bg-1" />
      <path d="M5.32569 21.2222C5.32569 21.9042 4.77062 22.4593 4.08859 22.4593H1.2371C0.555063 22.4593 0 21.9042 0 21.2222C0 20.5365 0.555063 19.9851 1.2371 19.9851H4.08859C4.77062 19.9815 5.32569 20.5365 5.32569 21.2222Z" fill="svg-linear-bg-2" />
      <path d="M43.8866 15.0984L40.9625 13.4078L21.3141 2.06348L18.39 0.376531C16.5362 -0.693686 14.218 0.644998 14.218 2.78543V7.60322H21.3141C21.9961 7.60322 22.5512 8.15827 22.5512 8.84031C22.5512 9.52235 21.9961 10.0774 21.3141 10.0774H7.50645C6.82079 10.0774 6.26935 10.6325 6.26935 11.3145C6.26935 11.9965 6.82442 12.5516 7.50645 12.5516H18.8834C19.5654 12.5516 20.1205 13.1067 20.1205 13.7887C20.1205 14.4707 19.5654 15.0258 18.8834 15.0258H2.2642C1.57853 15.0258 1.0271 15.5809 1.0271 16.2629C1.0271 16.9449 1.58216 17.5 2.2642 17.5H29.9991C30.6848 17.5 31.2362 18.0551 31.2362 18.7371C31.2362 19.4191 30.6812 19.9742 29.9991 19.9742H8.10141C7.41575 19.9742 6.86432 20.5293 6.86432 21.2113C6.86432 21.8933 7.41938 22.4484 8.10141 22.4484H23.0083C23.6903 22.4484 24.2454 23.0035 24.2454 23.6855C24.2454 24.3675 23.6903 24.9226 23.0083 24.9226H5.32611C4.64044 24.9226 4.08901 25.4776 4.08901 26.1597C4.08901 26.8417 4.64407 27.3968 5.32611 27.3968H14.2216V32.2146C14.2216 34.355 16.5398 35.6937 18.3936 34.6235L21.3177 32.9365L40.9661 21.5922L43.8902 19.9053C45.7404 18.8459 45.7404 16.1686 43.8866 15.0984ZM26.4729 15.0331H23.6214C22.9393 15.0331 22.3843 14.478 22.3843 13.796C22.3843 13.1103 22.9393 12.5589 23.6214 12.5589H26.4729C27.1585 12.5589 27.71 13.1139 27.71 13.796C27.71 14.478 27.1549 15.0331 26.4729 15.0331Z" fill="svg-linear-bg-3" />
      <path d="M5.32569 21.2222C5.32569 21.9042 4.77062 22.4593 4.08859 22.4593H1.2371C0.555063 22.4593 0 21.9042 0 21.2222C0 20.5365 0.555063 19.9851 1.2371 19.9851H4.08859C4.77062 19.9815 5.32569 20.5365 5.32569 21.2222Z" fill="svg-linear-bg-4" />
      <g opacity="0.5">
        <path opacity="0.3" d="M26.7716 2.59866C23.4696 8.3087 21.5769 14.9354 21.5769 22.0091C21.5769 29.0828 23.4696 35.7096 26.7716 41.4196L60.3887 22.0091L26.7716 2.59866Z" fill="url(#svg-linear-bg-5)" />
      </g>
      <defs>
        <linearGradient id="svg-linear-bg-1" x1="40.2156" y1="7.29475" x2="14.1981" y2="40.7157" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="0.9831" stopColor="#9E247A" />
        </linearGradient>
        <linearGradient id="svg-linear-bg-2" x1="4.97699" y1="24.5803" x2="1.71246" y2="28.7737" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="0.9831" stopColor="#9E247A" />
        </linearGradient>
        <linearGradient id="svg-linear-bg-3" x1="40.2156" y1="7.29475" x2="14.1981" y2="40.7157" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="0.9831" stopColor="#9E247A" />
        </linearGradient>
        <linearGradient id="svg-linear-bg-4" x1="4.97699" y1="24.5803" x2="1.71246" y2="28.7737" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="0.9831" stopColor="#9E247A" />
        </linearGradient>
        <linearGradient id="svg-linear-bg-5" x1="21.5749" y1="22.0089" x2="60.3899" y2="22.0089" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.4" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

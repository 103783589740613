import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function ContactIcon({ className }: KeyValueObject<string>) {
  return (
    <svg className={className} width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.6679 15.1702V13.42C32.6679 13.0988 32.4072 12.8381 32.086 12.8381H31.4902C31.183 5.70688 25.2899 0 18.0842 0C10.8784 0 4.98536 5.70688 4.67814 12.8335H4.08233C3.76115 12.8335 3.50047 13.0942 3.50047 13.4153V15.1656C1.5687 15.1656 0 16.7343 0 18.666V25.667C0 27.5987 1.5687 29.1674 3.50047 29.1674H4.08233V30.3358C4.08233 32.2676 5.65101 33.8363 7.58278 33.8363H15.1656V34.4181C15.1656 34.7393 15.4263 35 15.7474 35H20.4163C20.7375 35 20.9981 34.7393 20.9981 34.4181V32.086C20.9981 31.7649 20.7375 31.5042 20.4163 31.5042H15.7474C15.4263 31.5042 15.1656 31.7649 15.1656 32.086V32.6679H7.58278C6.29338 32.6679 5.25071 31.6206 5.25071 30.3358V29.1674H7.58278C7.90397 29.1674 8.16464 28.9068 8.16464 28.5856V27.4172H8.4579C9.58438 27.4172 10.4967 26.5002 10.5014 25.3737V18.9546C10.5014 17.8282 9.58438 16.9158 8.4579 16.9112H8.16464V13.4107C8.16464 7.94121 12.6147 3.49116 18.0842 3.49116C23.5536 3.49116 28.0037 7.94121 28.0037 13.4107V16.9112H27.7105C26.584 16.9112 25.6716 17.8282 25.667 18.9546V25.3737C25.667 26.5002 26.584 27.4126 27.7105 27.4172H28.0037V28.5856C28.0037 28.9068 28.2644 29.1674 28.5856 29.1674H32.6679C34.5997 29.1674 36.1684 27.5987 36.1684 25.667V18.666C36.1684 16.7343 34.6043 15.1702 32.6679 15.1702Z" fill="svg-linear-bg-1" />
      <path d="M18.0842 6.41907C14.216 6.41907 11.0833 9.5518 11.0833 13.42C11.0833 17.2882 14.216 20.4209 18.0842 20.4209C21.9524 20.4209 25.0851 17.2882 25.0851 13.42C25.0805 9.5518 21.9477 6.42373 18.0842 6.41907ZM15.794 8.05293C15.6869 8.23447 15.5892 8.42998 15.4914 8.63944C14.9701 9.78454 14.6582 11.2601 14.5977 12.8335H12.2796C12.4937 10.7202 13.8389 8.89081 15.794 8.05293ZM12.2796 14.0019H14.5977C14.6582 15.5799 14.9701 17.0508 15.4914 18.1959C15.5892 18.4054 15.6869 18.6009 15.794 18.7824C13.8389 17.9445 12.4937 16.1152 12.2796 14.0019ZM17.5023 19.0291C17.1625 18.7778 16.832 18.3262 16.5527 17.7164C16.0966 16.7157 15.8219 15.4123 15.7614 14.0019H17.4977V19.0291H17.5023ZM17.5023 12.8335H15.7661C15.8266 11.4231 16.1012 10.1197 16.5574 9.1189C16.8367 8.50911 17.1672 8.05293 17.507 7.80622V12.8335H17.5023ZM23.8888 12.8335H21.5707C21.5102 11.2555 21.1983 9.78454 20.6769 8.63944C20.5792 8.42998 20.4815 8.23447 20.3744 8.05293C22.3294 8.89081 23.6747 10.7202 23.8888 12.8335ZM18.6707 7.80622C19.0105 8.05758 19.341 8.50911 19.6203 9.1189C20.0765 10.1197 20.3511 11.4231 20.4116 12.8335H18.6754V7.80622H18.6707ZM18.6707 19.0291V14.0019H20.407C20.3465 15.4123 20.0718 16.7157 19.6156 17.7164C19.3364 18.3262 19.0058 18.7778 18.6707 19.0291ZM20.3791 18.7824C20.4861 18.6009 20.5838 18.4054 20.6816 18.1959C21.2029 17.0508 21.5148 15.5752 21.5753 14.0019H23.8935C23.6747 16.1152 22.3294 17.9445 20.3791 18.7824Z" fill="svg-linear-bg-2" />
      <defs>
        <linearGradient id="svg-linear-bg-1" x1="18.0842" y1="0.0139084" x2="18.0842" y2="35.0139" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="1" stopColor="#9E247A" />
        </linearGradient>
        <linearGradient id="svg-linear-bg-2" x1="18.0842" y1="6.43298" x2="18.0842" y2="20.4348" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED2542" />
          <stop offset="1" stopColor="#9E247A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

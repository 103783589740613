import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import InstagramIcon from '../svg/instagram';
import YoutubeIcon from '../svg/youtube';

import facebookIcon from '../../assets/icons/facebook.svg';
import globalLinks from "../../assets/data/links.json";

import './style.css';
import SocialNetworkBg from '../svg/social-network-bg';
import HomeIcon from '../svg/home';
import GrillIcon from '../svg/grill';
import ContactIcon from '../svg/contact';
import SharedIcon from '../svg/shared';
import Play from '../svg/play';

export default function Footer() {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      placeholderImage: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <footer className='text-white position-relative bg-black z-index_2'>

      <div className="footer__block position-relative bg-darkengrey">

        <div className="d-flex flex-column">

          <Link title="page d'accueil" className='footer__logo-block' to='/'>
            <img height={98} width={407} className='footer__logo img-fluid' src={data?.placeholderImage?.publicURL} />
          </Link>

          <div className="social-network__block flex_1 social-media__block d-flex justify-content-end align-items-center">
            <SocialNetworkBg className='social-network__bg' />
            <a className='social__link position-relative' title='instagram' href={globalLinks.social.instagram} target='_blank' rel='noopener noreferrer'>
              <InstagramIcon />
            </a>
            <a className='social__link position-relative' title='facebook' href={globalLinks.social.facebook} target='_blank' rel='noopener noreferrer'>
              <img src={facebookIcon} alt="facebook" />
            </a>
            <a className='social__link position-relative' title='youtube' href={globalLinks.social.youtube} target='_blank' rel='noopener noreferrer'>
              <YoutubeIcon />
            </a>
          </div>

        </div>

        <div className="d-flex align-items-center flex-column flex-md-row mt-2 mt-4">
          <div className="flex_1 d-none d-md-flex"></div>
          <ul className="list-unstyled w-100 d-flex justify-content-center justify-content-md-between flex_2 mb-3 mb-md-0">
            <li className="navbar__item nav-item text-center h-auto">
              <Link className="nav-link footer__link w-auto text-decoration-none text-white text-uppercase" to={globalLinks.home}>
                <HomeIcon className='nav-icon' />
                <span className="link-text">Accueil</span>
              </Link>
            </li>
            <li className="navbar__item nav-item text-center h-auto">
              <Link className="nav-link footer__link w-auto text-decoration-none text-white text-uppercase" to={globalLinks.grill}>
                <GrillIcon className='nav-icon' />
                <span className="link-text">Grill</span>
              </Link>
            </li>
            <li className="navbar__item nav-item text-center h-auto">
              <Link className="nav-link footer__link w-auto text-decoration-none text-white text-uppercase" to={globalLinks.contact}>
                <ContactIcon className='nav-icon' />
                <span className="link-text">contact</span>
              </Link>
            </li>
          </ul>
          <div className="flex_1 d-none d-flex flex-row flex-md-column flex-wrap justify-content-center align-items-end">
            <a rel='noopener noreferrer' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${data?.site?.siteMetadata?.siteUrl}`} className='btn btn__custom d-flex justify-content-between mb-0 mb-md-3 mr-0 mr-sm-3 mr-md-0 text-white bg_hover_white text_hover_gradient nav-link d-bold text-capitalize'>
              <span className="btn__text">partager</span>
              <SharedIcon className='nav-icon m-0 mr-2' />
            </a>
            <Link to={'/#video-live'} className='btn btn__custom d-flex justify-content-between text-white bg_hover_white text_hover_gradient nav-link d-bold text-capitalize'>
              <span className="btn__text">Live</span>
              <Play
                containerClassName='d-flex justify-content-center align-items-center play-icon__container bg-white'
                className='nav-icon m-0' />
            </Link>
          </div>
        </div>

      </div>

      <div className="pt-2 mt-md-2 py-md-4 d-flex flex-column align-items-center font-weight-light position-relative top-bar top-bar_size_small">
        <div className='text-center'>
          © 2021 Algone radio. All Rights Reserved.
        </div>
      </div>

    </footer>
  )
}

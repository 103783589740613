/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";


import Header from "../header";
import Footer from "../footer";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/shared.css';
// import '../../assets/css/media-query.css';

const Layout = ({ children, size, className = 'page-container', headerClassName = '' }: any) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={className}>
      <Header className={headerClassName} size={size} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className='main-content'>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
